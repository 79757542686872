import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ButtonModule } from 'primeng/button'
import { RippleModule } from 'primeng/ripple'

@Component({
  selector: 'tb-btn',
  standalone: true,
  template: `
    <button
      pButton
      pRipple
      type="button"
      [disabled]="disabled"
      [icon]="icon"
      [class]="'p-2 ' + class + (secondary ? ' btn-secondary' : '')"
      [size]="size"
      [severity]="severity"
      (click)="_onClick($event)"
      label="{{ label }}">
    </button>`,
  imports: [
    ButtonModule,
    RippleModule
  ],
})
export class ButtonComponent {

  @Input({ required: true }) label: string = ''
  @Input() class: string = ''
  @Input() icon: string = ''
  @Input() severity: 'success' | 'info' | 'warning' | 'danger' | 'help' | 'primary' | 'secondary' | 'contrast' | null | undefined = null
  @Input() size: 'small' | 'large' | undefined | null = null
  @Input() secondary: boolean = false
  @Input() disabled: boolean = false

  @Output() onClick = new EventEmitter<MouseEvent>()

  _onClick(event: MouseEvent) {
    this.onClick.emit(event)
  }

}
